import { Box, Link, Typography } from '@mui/material';
import { FC } from 'react';
import { CustomDialog, PageForm } from 'ui-components';
import { useOpenExternalLink } from '../hooks/useOpenExternalLink';

type ContactSupportDialogProps = { onClose: () => void };

const SUPPORT_LINK = `https://pmpediatriccare.com/contact-us/`;

export const ContactSupportDialog: FC<ContactSupportDialogProps> = ({ onClose }) => {
  const openExternalLink = useOpenExternalLink();

  return (
    <CustomDialog open={true} onClose={onClose}>
      <Typography variant="h2" color="primary.main" sx={{ mb: 2 }}>
        Need help?
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="body2">
          Call us: <span style={{ fontWeight: 700 }}>1-516-207-7950</span>
        </Typography>
        <Typography variant="body2" sx={{ mt: -1.5 }}>
          Sunday-Saturday 10am-10pm ET.
        </Typography>
        <Typography variant="body2">
          For wait times or more information:&nbsp;
          <Link sx={{ cursor: 'pointer' }} onClick={() => openExternalLink(SUPPORT_LINK)} target="_blank">
            Click Here
          </Link>
        </Typography>
        <Typography variant="body2">If this is an emergency, please call 911.</Typography>
      </Box>
      <PageForm
        onSubmit={onClose}
        controlButtons={{
          submitLabel: 'Ok',
          backButton: false,
        }}
      />
    </CustomDialog>
  );
};
