import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IntakeFlowPageRoute } from '../App';
import { otherColors } from '../IntakeThemeProvider';
import { contactSupport, pastVisits, requestVisit, stethoscope } from '../assets';
import { CancelVisitDialog } from '../components';
import { findActiveAppointment, useAppointmentStore, useGetAppointments } from '../features/appointments';
import { CustomContainer, useIntakeCommonStore } from '../features/common';
import HomepageOption from '../features/homepage/HomepageOption';
import { useOpenExternalLink } from '../hooks/useOpenExternalLink';
import { useAppointmentsData } from '../features/appointments';
import { useZapEHRAPIClient } from '../utils';

const Homepage = (): JSX.Element => {
  const apiClient = useZapEHRAPIClient();
  const navigate = useNavigate();
  const openExternalLink = useOpenExternalLink();
  const [isCancelVisitDialogOpen, setCancelVisitDialogOpen] = useState<boolean>(false);
  const { isAppointmentsFetching, refetchAppointments, appointments } = useAppointmentsData();
  const activeAppointment = useMemo(() => findActiveAppointment(appointments), [appointments]);
  const isAppointmentStatusProposed = activeAppointment?.appointmentStatus === 'proposed';
  const appointmentID = activeAppointment?.id || '';
  const { refetch } = useGetAppointments(apiClient, Boolean(apiClient));

  useEffect(() => {
    if (apiClient) {
      // TODO research option invalidate cache on the place to rid of useEffects with manually refetching
      void refetch();
    }
  }, [refetch, apiClient]);

  const handleRequestVisit = (): void => {
    navigate(`${IntakeFlowPageRoute.SelectPatient.path}?flow=requestVisit`);
  };

  const handleReturnToCall = (): void => {
    navigate(`${IntakeFlowPageRoute.WaitingRoom.path}?appointment_id=${appointmentID}`);
  };

  const handleContinueRequest = (): void => {
    useAppointmentStore.setState({ appointmentDate: activeAppointment?.start, appointmentID });
    navigate(`${IntakeFlowPageRoute.SelectPatient.path}?flow=continueVisitRequest`, {
      state: { patientId: activeAppointment?.patient?.id },
    });
  };

  const handlePastVisits = (): void => {
    navigate(`${IntakeFlowPageRoute.SelectPatient.path}?flow=pastVisits`);
  };

  const handleContactSupport = (): void => {
    useIntakeCommonStore.setState({ supportDialogOpen: true });
  };

  return (
    <CustomContainer
      title="Welcome to PM Pediatric Care"
      description=""
      bgVariant={IntakeFlowPageRoute.Homepage.path}
      isFirstPage={true}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        {isAppointmentsFetching ? (
          <Skeleton
            variant="rounded"
            height={115}
            sx={{
              borderRadius: 2,
              backgroundColor: otherColors.coachingVisit,
            }}
          />
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            {activeAppointment && (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'flex-start' }}>
                <HomepageOption
                  title={isAppointmentStatusProposed ? 'Continue Virtual Visit Request' : 'Return to Call'}
                  icon={requestVisit}
                  handleClick={isAppointmentStatusProposed ? handleContinueRequest : handleReturnToCall}
                  subSlot={
                    isAppointmentStatusProposed ? undefined : (
                      <Typography
                        variant="overline"
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          backgroundColor: '#FFD271',
                          color: '#A67100',
                          borderRadius: 1,
                          px: 1,
                        }}
                      >
                        Active call
                      </Typography>
                    )
                  }
                />
                {isAppointmentStatusProposed && (
                  <Button onClick={() => setCancelVisitDialogOpen(true)} startIcon={<CloseIcon />}>
                    Cancel this request
                  </Button>
                )}
              </Box>
            )}

            {/*{!isAppointmentStatusReady && (*/}
            {/*  <HomepageOption title="Request a Virtual Visit" icon={requestVisit} handleClick={handleRequestVisit} />*/}
            {/*)}*/}

            <HomepageOption title="Request a Virtual Visit" icon={requestVisit} handleClick={handleRequestVisit} />

            <HomepageOption
              title="Past Virtual Visits"
              icon={pastVisits}
              handleClick={handlePastVisits}
              subtitle="School/Work Notes and Prescriptions"
            />
          </Box>
        )}

        <HomepageOption
          title="Book Urgent Care Visit (In-Person)"
          icon={stethoscope}
          handleClick={() => openExternalLink('https://pmpediatriccare.com/book-your-urgent-care-visit/')}
        />

        <HomepageOption title="Contact Support" icon={contactSupport} handleClick={handleContactSupport} />
      </Box>
      {isCancelVisitDialogOpen ? (
        <CancelVisitDialog
          appointmentID={appointmentID}
          onClose={(canceled) => {
            setCancelVisitDialogOpen(false);
            if (canceled) void refetchAppointments();
          }}
        />
      ) : null}
    </CustomContainer>
  );
};

export default Homepage;
