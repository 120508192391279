import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import { useBeforeUnload, useLocation, useNavigate } from 'react-router-dom';
import {
  filterObject,
  formInputStringToBoolean,
  FormInputTypeField,
  OverrideValues,
  PageForm,
  SelectInputOption,
} from 'ui-components';
import { FileURLs, getPatientChosenName, getSelectors } from 'utils';
import { IntakeFlowPageRoute } from '../App';
import { useAppointmentStore } from '../features/appointments';
import { CustomContainer } from '../features/common';
import { useCreateZ3ObjectMutation, useFilesStore } from '../features/files';
import { createIOSMesssageOpenPageExternal, sendIOSAppMessage } from '../features/ios-communication';
import { useIOSAppSync } from '../features/ios-communication/useIOSAppSync';
import { usePaperworkStore } from '../features/paperwork';
import { usePatientInfoStore } from '../features/patient-info';
import { useMapQuestionsToFormInputFields, usePaperworkPageInfo, useZapEHRAPIClient } from '../utils';

const mapSexToOption = {
  female: 'Female',
  male: 'Male',
  other: 'Intersex',
  unknown: 'Intersex',
};

const PaperworkPage = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isIOSApp } = useIOSAppSync();

  const apiClient = useZapEHRAPIClient();
  const createZ3Object = useCreateZ3ObjectMutation();

  const { paperworkQuestions, patchCompletedPaperwork } = getSelectors(usePaperworkStore, [
    'paperworkQuestions',
    'patchCompletedPaperwork',
  ]);
  const { completedPaperwork } = usePaperworkStore.getState();
  const { patientInfo } = getSelectors(usePatientInfoStore, ['patientInfo']);
  const { appointmentID } = getSelectors(useAppointmentStore, ['appointmentID']);
  const { fileURLs, patchFileURLs, fileUploads, setFileUploads } = getSelectors(useFilesStore, [
    'fileURLs',
    'patchFileURLs',
    'fileUploads',
    'setFileUploads',
  ]);

  const { items, nextPage, pageName, currentPage, currentIndex } = usePaperworkPageInfo({
    location,
    paperworkQuestions,
  });

  // File upload variables
  const fileItems = items.filter((item) => item.type === 'File');
  const allFileKeys = Object.keys(fileUploads);

  // Create object keys for each file input using the input name if the key doesn't already exist
  useEffect(() => {
    if (fileItems.length > 0) {
      fileItems.forEach((fileItem) => {
        !(fileItem.id in fileUploads) &&
          setFileUploads((prev) => ({ ...prev, [fileItem.id]: { fileData: null, uploadFailed: false } }));
      });
    }
  }, [fileItems, fileUploads, setFileUploads]);

  // support opening PDFs and links as external browser
  useEffect(() => {
    const listener = (event: Event): void => {
      const targetElement = event.target as HTMLElement;
      if (targetElement instanceof HTMLAnchorElement) {
        try {
          const link = targetElement.href;
          if (link) {
            const url = new URL(link);
            if (!link.startsWith('blob') && url.origin !== window.location.origin) {
              console.log(link);
              sendIOSAppMessage(createIOSMesssageOpenPageExternal(link));
            }
          }
        } catch {
          console.error('Unable to process link opening in IOS', targetElement.href);
        }
      }
    };
    if (isIOSApp) {
      document.addEventListener('click', listener);
    }
    return () => document.removeEventListener('click', listener);
  }, [isIOSApp]);

  useBeforeUnload(() => {
    const localURLsReset: FileURLs = {};
    Object.keys(fileURLs || {}).forEach((fileKey) => {
      localURLsReset[fileKey] = { ...fileURLs?.[fileKey], localUrl: undefined };
    });
    patchFileURLs(localURLsReset);
    setFileUploads({});
  });

  const onSubmit = useCallback(
    async (data: FieldValues): Promise<void> => {
      if (!paperworkQuestions) {
        throw new Error('paperworkQuestions is not defined');
      }

      // Upload files to z3 if the page includes File type input
      if (fileItems.length > 0) {
        let uploadResponse: any;

        for (const fileItem of fileItems) {
          const fileId = fileItem.id;
          const fileData = fileUploads[fileId].fileData;

          if (appointmentID && fileData) {
            await createZ3Object.mutateAsync(
              {
                apiClient,
                fileType: fileId,
                fileFormat: fileData?.type.split('/')[1],
                file: fileData,
                appointmentID,
              },
              {
                onSuccess: (response) => {
                  uploadResponse = response;
                },
                onError: (error) => {
                  throw error;
                },
              }
            );
          }

          if (fileData && !uploadResponse) {
            // Reset fields if Z3 upload fails
            setFileUploads((prev) => ({
              ...prev,
              [fileId]: { fileData: null, uploadFailed: true },
            }));
            data[fileId] = undefined;
            return;
          } else if (fileData && uploadResponse) {
            data[fileId] = uploadResponse.z3URL;
            // Reset file data when user continues to next  page
            setFileUploads((prev) => ({
              ...prev,
              [fileId]: { fileData: null, uploadFailed: prev[fileId].uploadFailed },
            }));
          } else {
            // Reset state for files because upload blob url overwrites Front and Back Url data
            // Only when cards are not being cleared
            if (data[fileId]) {
              data[fileId] = fileURLs?.[fileId]?.z3Url;
            }
          }
        }
      }

      // Update state.fileURLs
      if (fileItems.length > 0) {
        const formFileKeys = fileItems.map((item) => item.id);
        const fileUploadData: any = filterObject(data, (key) => formFileKeys.includes(key));
        const fileURLsUpdated: FileURLs = {};
        formFileKeys.forEach((key) => {
          fileURLsUpdated[key] = {
            ...fileURLs?.[key],
            z3Url: fileUploadData[key],
            localUrl: fileUploadData[key] ? fileURLs?.[key].localUrl : undefined, // Reset localUrl if no data[fileId]
          };
        });
        patchFileURLs(fileURLsUpdated);
      }

      // Update completed paperwork state
      // Filter out file data
      formInputStringToBoolean(data, items);
      const paperworkData = filterObject(data, (key) => !allFileKeys.includes(key));
      patchCompletedPaperwork(paperworkData);

      if (currentIndex === paperworkQuestions.length - 1) {
        navigate(IntakeFlowPageRoute.ReviewPaperwork.path);
      } else {
        navigate(`/paperwork/${nextPage?.slug || ''}`);
      }
    },
    [
      paperworkQuestions,
      fileItems,
      items,
      patchCompletedPaperwork,
      currentIndex,
      fileUploads,
      appointmentID,
      createZ3Object,
      apiClient,
      setFileUploads,
      fileURLs,
      patchFileURLs,
      allFileKeys,
      navigate,
      nextPage?.slug,
    ]
  );

  const mapQuestionsToFormInputFields = useMapQuestionsToFormInputFields({
    getLabel: (item) => item.text.replace('{patientFirstName}', getPatientChosenName(patientInfo)),
    getDefaultValue: (item) =>
      fileURLs?.[item.id]
        ? fileURLs?.[item.id]?.localUrl || fileURLs?.[item.id]?.presignedUrl
        : completedPaperwork[item.id],
    getFileOptions: (item) => ({
      description: item.attachmentText,
      onUpload: setFileUploads,
      uploadFile: (fileType: string, tempURL: string) =>
        patchFileURLs({ [fileType]: { ...fileURLs?.[fileType], localUrl: tempURL } }),
      uploadFailed: fileUploads[item.id]?.uploadFailed,
      resetUploadFailed: () =>
        setFileUploads((prev) => ({
          ...prev,
          [item.id]: { ...prev[item.id], uploadFailed: false },
        })),
      onClear: () => {
        setFileUploads((prev) => ({
          ...prev,
          [item.id]: { ...prev[item.id], fileData: null },
        }));
        patchFileURLs({
          [item.id]: { localUrl: undefined, presignedUrl: undefined, z3Url: undefined },
        });
      },
      fileType: item.id,
    }),
  });

  const onFormValuesChange = useCallback(
    (formValues: FieldValues): void => {
      patchCompletedPaperwork(filterObject(formValues, (key) => !allFileKeys.includes(key)));
    },
    [allFileKeys, patchCompletedPaperwork]
  );

  const formElements = useMemo(() => {
    const mapped = mapQuestionsToFormInputFields(items);

    return mapped.map((item) => {
      if (item.name === 'surgical-history') {
        (item as FormInputTypeField).item = (item as FormInputTypeField).item?.map((surgicalItem) => {
          if (surgicalItem.name === 'surgical-history-form-type') {
            if (
              patientInfo.sex !== 'female' ||
              !patientInfo.dateOfBirth ||
              DateTime.fromISO(patientInfo.dateOfBirth) > DateTime.now().minus({ years: 15 })
            ) {
              surgicalItem.freeSelectOptions = (
                surgicalItem.freeSelectOptions as SelectInputOption[] | undefined
              )?.filter((option) => option.value !== 'C-section (Cesarean delivery)');
            }
          }
          return surgicalItem;
        });
      }
      return item;
    });
  }, [mapQuestionsToFormInputFields, items, patientInfo.sex, patientInfo.dateOfBirth]);

  function isConsentFormsComplete(paperwork: any): boolean {
    return Boolean(
      paperwork['hipaa-acknowledgement'] &&
        paperwork['consent-to-treat'] &&
        paperwork['signature'] &&
        paperwork['full-name'] &&
        paperwork['consent-form-signer-relationship']
    );
  }

  const overrideValues = useMemo(() => {
    const prevValues = usePaperworkStore.getState().completedPaperwork;

    const dob =
      patientInfo.dateOfBirth && patientInfo.dateOfBirth.includes('T')
        ? patientInfo.dateOfBirth.split('T')[0]
        : patientInfo.dateOfBirth;

    const overrideValues: OverrideValues = {
      'patient-first-name': patientInfo.firstName,
      'patient-last-name': patientInfo.lastName,
      'patient-dob': dob,
      'patient-birth-sex': mapSexToOption[patientInfo.sex || 'unknown'],
    };

    if (!(prevValues && prevValues['responsible-party-number'])) {
      overrideValues['account-phone-number'] = prevValues['patient-number'];
    }

    return overrideValues;
  }, [patientInfo.dateOfBirth, patientInfo.firstName, patientInfo.lastName, patientInfo.sex]);

  return (
    <CustomContainer
      title={pageName}
      description={items[0]?.type === 'Description' ? items[0]?.text : undefined}
      bgVariant={currentPage.slug}
    >
      <PageForm
        formElements={formElements}
        onSubmit={onSubmit}
        onFormValuesChange={onFormValuesChange}
        overrideValues={overrideValues}
        controlButtons={useMemo(
          () => ({
            loading: createZ3Object.isLoading,
            onBack:
              currentIndex === 0 && !isConsentFormsComplete(completedPaperwork)
                ? () => navigate(IntakeFlowPageRoute.PatientInformation.path)
                : () => navigate(-1),
          }),
          [createZ3Object.isLoading, navigate, currentIndex, completedPaperwork]
        )}
      />
    </CustomContainer>
  );
};

export default PaperworkPage;
