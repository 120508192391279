import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'ui-components/lib/styles/main.css';
import App from './App';

const { VITE_APP_AUTH0_AUDIENCE, VITE_APP_AUTH_URL, VITE_APP_CLIENT_ID } = import.meta.env;
if (!VITE_APP_CLIENT_ID || !VITE_APP_AUTH0_AUDIENCE) {
  throw new Error('Client ID or audience not found');
}
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Auth0Provider
      domain={VITE_APP_AUTH_URL}
      clientId={VITE_APP_CLIENT_ID}
      connection="sms"
      redirectUri={`${window.location.origin}`}
      audience={VITE_APP_AUTH0_AUDIENCE}
      useRefreshTokens={true}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>
);
