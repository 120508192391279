import { ZambdaClient } from '@zapehr/sdk';

import {
  CancelAppointmentRequestParams,
  CancelInviteParticipantRequestParameters,
  CancelInviteParticipantResponse,
  CreateAppointmentUCTelemedParams,
  CreateAppointmentUCTelemedResponse,
  CreatePaperworkInput,
  CreatePaperworkResponse,
  CreditCardInfo,
  GetEligibilityParameters,
  GetEligibilityResponse,
  GetInsurancesResponse,
  GetLocationRequestParams,
  GetLocationResponse,
  GetPaperworkRequestParams,
  GetTelemedAppointmentsRequest,
  GetTelemedAppointmentsResponse,
  GetTelemedLocationsResponse,
  GetVisitDetailsRequest,
  GetVisitDetailsResponse,
  InviteParticipantRequestParameters,
  JoinCallRequestParameters,
  JoinCallResponse,
  ListInvitedParticipantsRequestParameters,
  ListInvitedParticipantsResponse,
  PaperworkResponseWithResponses,
  PaperworkResponseWithoutResponses,
  PatientInfo,
  PaymentMethodDeleteParameters,
  PaymentMethodListParameters,
  PaymentMethodSetDefaultParameters,
  PaymentMethodSetupParameters,
  UpdateAppointmentRequestParams,
  UpdateAppointmentResponse,
  UpdatePaperworkInput,
  UpdatePaperworkResponse,
  VideoChatCreateInviteResponse,
  WaitingRoomInput,
  WaitingRoomResponse,
  isoStringFromMDYString,
} from 'utils';
import { ApiError, GetZapEHRAPIParams } from '../types/data';

enum ZambdaNames {
  'cancel appointment' = 'cancel appointment',
  'check in' = 'check in',
  'create appointment' = 'create appointment',
  'create paperwork' = 'create paperwork',
  'delete payment method' = 'delete payment method',
  'get appointments' = 'get appointments',
  'get eligibility' = 'get eligibility',
  'get visit details' = 'get visit details',
  'get insurances' = 'get insurances',
  'get location' = 'get location',
  'get paperwork' = 'get paperwork',
  'get patients' = 'get patients',
  'get payment methods' = 'get payment methods',
  'get presigned file url' = 'get presigned file url',
  'get telemed states' = 'get telemed states',
  'get wait status' = 'get wait status',
  'join call' = 'join call',
  'setup payment method' = 'setup payment method',
  'set default payment method' = 'set default payment method',
  'update appointment' = 'update appointment',
  'update paperwork' = 'update paperwork',
  'video chat cancel invite' = 'video chat cancel invite',
  'video chat create invite' = 'video chat create invite',
  'video chat list invites' = 'video chat list invites',
}

const zambdasPublicityMap: Record<keyof typeof ZambdaNames, boolean> = {
  'cancel appointment': false,
  'check in': true,
  'create appointment': false,
  'create paperwork': false,
  'delete payment method': false,
  'get appointments': false,
  'get eligibility': false,
  'get visit details': false,
  'get insurances': false,
  'get location': true,
  'get paperwork': false,
  'get patients': false,
  'get payment methods': false,
  'get presigned file url': true,
  'get telemed states': true,
  'get wait status': true,
  'join call': true,
  'setup payment method': false,
  'set default payment method': false,
  'update appointment': false,
  'update paperwork': false,
  'video chat cancel invite': false,
  'video chat create invite': false,
  'video chat list invites': false,
};

export type ZapEHRAPIClient = ReturnType<typeof getZapEHRAPI>;

export const getZapEHRAPI = (
  params: GetZapEHRAPIParams,
  zambdaClient: ZambdaClient
): {
  cancelAppointment: typeof cancelAppointment;
  checkIn: typeof checkIn;
  createAppointment: typeof createAppointment;
  createPaperwork: typeof createPaperwork;
  createZ3Object: typeof createZ3Object;
  deletePaymentMethod: typeof deletePaymentMethod;
  getAppointments: typeof getAppointments;
  getEligibility: typeof getEligibility;
  getVisitDetails: typeof getVisitDetails;
  getInsurances: typeof getInsurances;
  getLocation: typeof getLocation;
  getPaperworkPublic: typeof getPaperworkPublic;
  getPaperwork: typeof getPaperwork;
  getPatients: typeof getPatients;
  getPaymentMethods: typeof getPaymentMethods;
  getTelemedStates: typeof getTelemedStates;
  getWaitStatus: typeof getWaitStatus;
  joinCall: typeof joinCall;
  setDefaultPaymentMethod: typeof setDefaultPaymentMethod;
  setupPaymentMethod: typeof setupPaymentMethod;
  updateAppointment: typeof updateAppointment;
  updatePaperwork: typeof updatePaperwork;
  videoChatCancelInvite: typeof videoChatCancelInvite;
  videoChatCreateInvite: typeof videoChatCreateInvite;
  videoChatListInvites: typeof videoChatListInvites;
} => {
  const {
    cancelAppointmentZambdaID,
    checkInZambdaID,
    createAppointmentZambdaID,
    createPaperworkZambdaID,
    deletePaymentMethodZambdaID,
    getAppointmentsZambdaID,
    getEligibilityZambdaID,
    getVisitDetailsZambdaID,
    getInsurancesZambdaID,
    getLocationZambdaID,
    getPaperworkZambdaID,
    getPatientsZambdaID,
    getPaymentMethodsZambdaID,
    getPresignedFileURLZambdaID,
    getTelemedStatesZambdaID,
    getWaitStatusZambdaID,
    joinCallZambdaID,
    setDefaultPaymentMethodZambdaID,
    setupPaymentMethodZambdaID,
    updateAppointmentZambdaID,
    updatePaperworkZambdaID,
    videoChatCancelInviteZambdaID,
    videoChatCreateInviteZambdaID,
    videoChatListInvitesZambdaID,
  } = params;

  const zambdasToIdsMap: Record<keyof typeof ZambdaNames, string | undefined> = {
    'cancel appointment': cancelAppointmentZambdaID,
    'check in': checkInZambdaID,
    'create appointment': createAppointmentZambdaID,
    'create paperwork': createPaperworkZambdaID,
    'delete payment method': deletePaymentMethodZambdaID,
    'get appointments': getAppointmentsZambdaID,
    'get eligibility': getEligibilityZambdaID,
    'get visit details': getVisitDetailsZambdaID,
    'get insurances': getInsurancesZambdaID,
    'get location': getLocationZambdaID,
    'get paperwork': getPaperworkZambdaID,
    'get patients': getPatientsZambdaID,
    'get payment methods': getPaymentMethodsZambdaID,
    'get presigned file url': getPresignedFileURLZambdaID,
    'get telemed states': getTelemedStatesZambdaID,
    'get wait status': getWaitStatusZambdaID,
    'join call': joinCallZambdaID,
    'set default payment method': setDefaultPaymentMethodZambdaID,
    'setup payment method': setupPaymentMethodZambdaID,
    'update appointment': updateAppointmentZambdaID,
    'update paperwork': updatePaperworkZambdaID,
    'video chat cancel invite': videoChatCancelInviteZambdaID,
    'video chat create invite': videoChatCreateInviteZambdaID,
    'video chat list invites': videoChatListInvitesZambdaID,
  };
  const isAppLocalProvided = params.isAppLocal != null;
  const isAppLocal = params.isAppLocal === 'true';

  const verifyZambdaProvidedAndNotLocalThrowErrorOtherwise = (
    zambdaID: string | undefined,
    zambdaName: keyof typeof zambdasToIdsMap
  ): zambdaID is Exclude<typeof zambdaID, undefined> => {
    if (zambdaID === undefined || !isAppLocalProvided) {
      throw new Error(`${zambdaName} zambda environment variable could not be loaded`);
    }
    return true;
  };

  const chooseJson = (json: any): any => {
    return isAppLocal ? json : json.output;
  };

  const makeZapRequest = async <TResponse, TPayload>(
    zambdaName: keyof typeof ZambdaNames,
    payload?: TPayload,
    additionalErrorHandler?: (error: unknown) => void
  ): Promise<TResponse> => {
    const zambdaId = zambdasToIdsMap[zambdaName];

    try {
      if (verifyZambdaProvidedAndNotLocalThrowErrorOtherwise(zambdaId, zambdaName)) {
        let zambdaMethodToInvoke: ZambdaClient['invokeZambda'] | ZambdaClient['invokePublicZambda'];

        if (zambdasPublicityMap[zambdaName]) {
          zambdaMethodToInvoke = zambdaClient.invokePublicZambda;
        } else {
          zambdaMethodToInvoke = zambdaClient.invokeZambda;
        }

        zambdaMethodToInvoke = zambdaMethodToInvoke.bind(zambdaClient);

        const response = await zambdaMethodToInvoke({
          zambdaId: zambdaId,
          payload,
        });

        const jsonToUse = chooseJson(response);
        return jsonToUse;
      }
      // won't be reached, but for TS to give the right return type
      throw Error();
    } catch (error) {
      additionalErrorHandler && additionalErrorHandler(error);
      throw apiErrorToThrow(error);
    }
  };

  // Zambdas

  const cancelAppointment = async (parameters: CancelAppointmentRequestParams): Promise<any> => {
    return await makeZapRequest('cancel appointment', parameters, NotFoundApointmentErrorHandler);
  };

  const checkIn = async (appointmentId: string): Promise<any> => {
    return await makeZapRequest('check in', { appointment: appointmentId }, NotFoundApointmentErrorHandler);
  };

  const createAppointment = async (
    parameters: CreateAppointmentUCTelemedParams
  ): Promise<CreateAppointmentUCTelemedResponse> => {
    const fhirParams = fhirifyAppointmentInputs({ ...parameters });
    return await makeZapRequest('create appointment', fhirParams);
  };

  const createPaperwork = async (
    parameters: Pick<
      CreatePaperworkInput,
      'appointmentID' | 'files' | 'paperwork' | 'paperworkComplete' | 'timezone' | 'patientId'
    >
  ): Promise<CreatePaperworkResponse> => {
    const payload = Object.fromEntries(
      Object.entries(parameters).filter(
        ([_parameterKey, parameterValue]) =>
          parameterValue && !Object.values(parameterValue).every((tempValue) => tempValue === undefined)
      )
    );
    return await makeZapRequest('create paperwork', payload);
  };

  const createZ3Object = async (
    appointmentID: string,
    fileType: string,
    fileFormat: string,
    file: File
  ): Promise<any> => {
    try {
      const presignedURLRequest = await getPresignedFileURL(appointmentID, fileType, fileFormat);

      // const presignedURLResponse = await presignedURLRequest.json();
      // Upload the file to S3
      const uploadResponse = await fetch(presignedURLRequest.presignedURL, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
        },
        body: file,
      });

      if (!uploadResponse.ok) {
        throw new Error('Failed to upload file');
      }

      return presignedURLRequest;
    } catch (error: unknown) {
      throw apiErrorToThrow(error);
    }
  };

  const deletePaymentMethod = async (parameters: PaymentMethodDeleteParameters): Promise<unknown> => {
    return await makeZapRequest('delete payment method', parameters);
  };

  const getAppointments = async (
    parameters?: GetTelemedAppointmentsRequest
  ): Promise<GetTelemedAppointmentsResponse> => {
    return await makeZapRequest('get appointments', parameters);
  };

  const getEligibility = async (parameters: GetEligibilityParameters): Promise<GetEligibilityResponse> => {
    return await makeZapRequest('get eligibility', parameters);
  };

  const getVisitDetails = async (parameters: GetVisitDetailsRequest): Promise<GetVisitDetailsResponse> => {
    return await makeZapRequest('get visit details', parameters, NotFoundApointmentErrorHandler);
  };

  const getInsurances = async (): Promise<GetInsurancesResponse> => {
    return await makeZapRequest('get insurances');
  };

  const getLocation = async (parameters: GetLocationRequestParams): Promise<GetLocationResponse> => {
    return await makeZapRequest('get location', parameters);
  };

  const getPaperworkPublic = async (
    parameters: GetPaperworkRequestParams
  ): Promise<PaperworkResponseWithoutResponses> => {
    return await makeZapRequest('get paperwork', parameters, NotFoundApointmentErrorHandler);
  };

  const getPaperwork = async (parameters: GetPaperworkRequestParams): Promise<PaperworkResponseWithResponses> => {
    return await makeZapRequest('get paperwork', parameters, NotFoundApointmentErrorHandler);
  };

  const getPatients = async (): Promise<{ patients: PatientInfo[] }> => {
    return await makeZapRequest('get patients');
  };

  const getPaymentMethods = async (parameters: PaymentMethodListParameters): Promise<{ cards: CreditCardInfo[] }> => {
    return await makeZapRequest('get payment methods', parameters);
  };

  const getPresignedFileURL = async (appointmentID: string, fileType: string, fileFormat: string): Promise<any> => {
    const payload = {
      appointmentID,
      fileType,
      fileFormat,
    };
    return await makeZapRequest('get presigned file url', payload);
  };

  const getTelemedStates = async (): Promise<GetTelemedLocationsResponse> => {
    return await makeZapRequest('get telemed states');
  };

  const getWaitStatus = async (
    parameters: Omit<WaitingRoomInput, 'secrets' | 'authorization'>
  ): Promise<WaitingRoomResponse> => {
    return await makeZapRequest('get wait status', parameters);
  };

  const joinCall = async (parameters: JoinCallRequestParameters): Promise<JoinCallResponse> => {
    return await makeZapRequest('join call', parameters);
  };

  const setDefaultPaymentMethod = async (parameters: PaymentMethodSetDefaultParameters): Promise<unknown> => {
    return await makeZapRequest('set default payment method', parameters);
  };

  const setupPaymentMethod = async (parameters: PaymentMethodSetupParameters): Promise<{ clientSecret: string }> => {
    return await makeZapRequest('setup payment method', parameters);
  };

  const updateAppointment = async (parameters: UpdateAppointmentRequestParams): Promise<UpdateAppointmentResponse> => {
    return await makeZapRequest('update appointment', parameters);
  };

  const updatePaperwork = async (
    parameters: Pick<UpdatePaperworkInput, 'appointmentID' | 'files' | 'paperwork' | 'timezone'>
  ): Promise<UpdatePaperworkResponse> => {
    const payload = Object.fromEntries(
      Object.entries(parameters).filter(
        ([_parameterKey, parameterValue]) =>
          parameterValue && !Object.values(parameterValue).every((tempValue) => tempValue === undefined)
      )
    );
    return await makeZapRequest('update paperwork', payload);
  };

  const videoChatCancelInvite = async (
    parameters: CancelInviteParticipantRequestParameters
  ): Promise<CancelInviteParticipantResponse> => {
    return await makeZapRequest('video chat cancel invite', parameters);
  };

  const videoChatCreateInvite = async (
    parameters: InviteParticipantRequestParameters
  ): Promise<VideoChatCreateInviteResponse> => {
    return await makeZapRequest('video chat create invite', parameters);
  };

  const videoChatListInvites = async (
    parameters: ListInvitedParticipantsRequestParameters
  ): Promise<ListInvitedParticipantsResponse> => {
    return await makeZapRequest('video chat list invites', parameters);
  };

  return {
    cancelAppointment,
    checkIn,
    createAppointment,
    createPaperwork,
    createZ3Object,
    deletePaymentMethod,
    getAppointments,
    getEligibility,
    getVisitDetails,
    getInsurances,
    getLocation,
    getPaperworkPublic,
    getPaperwork,
    getPatients,
    getPaymentMethods,
    getTelemedStates,
    getWaitStatus,
    joinCall,
    setDefaultPaymentMethod,
    setupPaymentMethod,
    updateAppointment,
    updatePaperwork,
    videoChatCancelInvite,
    videoChatCreateInvite,
    videoChatListInvites,
  };
};

const fhirifyAppointmentInputs = (inputs: CreateAppointmentUCTelemedParams): CreateAppointmentUCTelemedParams => {
  const returnParams = { ...inputs };
  const { patient } = returnParams;

  const { dateOfBirth: patientBirthDate } = patient as PatientInfo;
  if (patient) {
    patient.dateOfBirth = isoStringFromMDYString(patientBirthDate ?? '');
  }

  returnParams.patient = patient;

  return returnParams;
};

const InternalError: ApiError = {
  message: 'Internal Service Error',
};

const isApiError = (error: any): boolean => error instanceof Object && error && 'message' in error;

export const apiErrorToThrow = (error: any): ApiError => {
  console.error(`Top level catch block:\nError: ${error}\nError stringified: ${JSON.stringify(error)}`);
  if (isApiError(error)) {
    return error;
  } else {
    console.error('An endpoint threw and did not provide a well formed ApiError');
    return InternalError;
  }
};

function NotFoundApointmentErrorHandler(error: any): void {
  if (error.message === 'Appointment is not found') {
    throw error;
  }
}
