export const MINIMUM_AGE = 0;
export const MAXIMUM_AGE = 26;

export const IOSAppCookieName = 'PMPTiOSApp';

export const stateWorkingSchedule: {
  [key: string]: { weekdays: string; weekends: string; holidays: string; timeZone: string };
} = {
  AK: { weekdays: '8 AM – 12 AM', weekends: '8 AM – 12 AM', holidays: '8 AM – 12 AM', timeZone: 'America/Anchorage' },
  // temporarily available always
  CA: { weekdays: '0 AM – 0 AM', weekends: '0 AM – 0 AM', holidays: '0 AM – 0 AM', timeZone: 'America/Los_Angeles' },
  CT: { weekdays: '6 AM – 12 AM', weekends: '8 AM – 12 AM', holidays: '8 AM – 12 AM', timeZone: 'America/New_York' },
  DC: { weekdays: '6 AM – 12 AM', weekends: '8 AM – 12 AM', holidays: '8 AM – 12 AM', timeZone: 'America/New_York' },
  DE: { weekdays: '8 AM – 12 AM', weekends: '8 AM – 12 AM', holidays: '8 AM – 12 AM', timeZone: 'America/New_York' },
  FL: { weekdays: '6 AM – 10 PM', weekends: '6 AM – 10 PM', holidays: '6 AM – 10 PM', timeZone: 'America/New_York' },
  IL: { weekdays: '8 AM – 12 AM', weekends: '8 AM – 12 AM', holidays: '8 AM – 12 AM', timeZone: 'America/Chicago' },
  MA: { weekdays: '6 AM – 10 PM', weekends: '8 AM – 10 PM', holidays: '8 AM – 10 PM', timeZone: 'America/New_York' },
  MD: { weekdays: '6 AM – 12 AM', weekends: '8 AM – 12 AM', holidays: '8 AM – 12 AM', timeZone: 'America/New_York' },
  NC: { weekdays: '6 AM – 10 PM', weekends: '6 AM – 10 PM', holidays: '6 AM – 10 PM', timeZone: 'America/New_York' },
  NJ: { weekdays: '8 AM – 12 AM', weekends: '8 AM – 12 AM', holidays: '8 AM – 12 AM', timeZone: 'America/New_York' },
  NY: { weekdays: '6 AM – 12 AM', weekends: '8 AM – 12 AM', holidays: '8 AM – 12 AM', timeZone: 'America/New_York' },
  PA: { weekdays: '8 AM – 12 AM', weekends: '8 AM – 12 AM', holidays: '8 AM – 12 AM', timeZone: 'America/New_York' },
  TN: { weekdays: '6 AM – 10 PM', weekends: '6 AM – 10 PM', holidays: '6 AM – 10 PM', timeZone: 'America/Chicago' },
  TX: { weekdays: '6 AM – 10 PM', weekends: '6 AM – 10 PM', holidays: '6 AM – 10 PM', timeZone: 'America/Chicago' },
  VA: { weekdays: '6 AM – 12 AM', weekends: '8 AM – 12 AM', holidays: '8 AM – 12 AM', timeZone: 'America/New_York' },
};

export const federalHolidays: { [key: string]: Set<string> } = {
  '2024': new Set([
    '2024-01-01',
    '2024-01-15',
    '2024-02-19',
    '2024-05-27',
    '2024-06-19',
    '2024-07-04',
    '2024-09-02',
    '2024-10-14',
    '2024-11-11',
    '2024-11-28',
    '2024-12-25',
  ]),
  '2025': new Set([
    '2025-01-01',
    '2025-01-20',
    '2025-02-17',
    '2025-05-26',
    '2025-06-19',
    '2025-07-04',
    '2025-09-01',
    '2025-10-13',
    '2025-11-11',
    '2025-11-27',
    '2025-12-25',
  ]),
  '2026': new Set([
    '2026-01-01',
    '2026-01-19',
    '2026-02-16',
    '2026-05-25',
    '2026-06-19',
    '2026-07-03',
    '2026-09-07',
    '2026-10-12',
    '2026-11-11',
    '2026-11-26',
    '2026-12-25',
  ]),
  '2027': new Set([
    '2027-01-01',
    '2027-01-18',
    '2027-02-15',
    '2027-05-31',
    '2027-06-21',
    '2027-07-05',
    '2027-09-06',
    '2027-10-11',
    '2027-11-11',
    '2027-11-25',
    '2027-12-24',
  ]),
  '2028': new Set([
    '2028-01-01',
    '2028-01-17',
    '2028-02-21',
    '2028-05-29',
    '2028-06-19',
    '2028-07-04',
    '2028-09-04',
    '2028-10-09',
    '2028-11-11',
    '2028-11-23',
    '2028-12-25',
  ]),
  '2029': new Set([
    '2029-01-01',
    '2029-01-15',
    '2029-02-19',
    '2029-05-28',
    '2029-06-19',
    '2029-07-04',
    '2029-09-03',
    '2029-10-08',
    '2029-11-12',
    '2029-11-22',
    '2029-12-25',
  ]),
  '2030': new Set([
    '2030-01-01',
    '2030-01-21',
    '2030-02-18',
    '2030-05-27',
    '2030-06-19',
    '2030-07-04',
    '2030-09-02',
    '2030-10-14',
    '2030-11-11',
    '2030-11-28',
    '2030-12-25',
  ]),
};
