import { CustomContainerFactory } from 'ui-components';
import { IntakeFlowPageRoute } from '../../App';
import { bg1, pmLogo } from '../../assets';
import Footer from '../../components/Footer';

const imageForBackground = (page: string): string => {
  switch (page) {
    case IntakeFlowPageRoute.Homepage.path:
      return bg1;
    default:
      return bg1;
  }
};

export const CustomContainer = CustomContainerFactory({
  imageForBackground,
  logo: pmLogo,
  alt: 'PM Pediatrics',
  footer: <Footer />,
  logoutUrl: `${window.location.host}/home`,
});
