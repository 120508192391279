import { ReactElement } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { MixpanelContextProps, ScrollToTop, setupMixpanel, setupSentry } from 'ui-components';
import { IntakeThemeProvider } from './IntakeThemeProvider';
import { ErrorAlert } from './components/ErrorAlert';
import { IOSMessagesHandler } from './components/IOSMessagesHandler';
import { ProtectedRoute } from './features/auth';
import { ErrorFallbackScreen, LoadingScreen } from './features/common';
import { useIOSAppSync } from './features/ios-communication/useIOSAppSync';
import './lib/i18n';
import AuthPage from './pages/AuthPage';
import CallEndedPage from './pages/CallEndedPage';
import ConfirmDateOfBirth from './pages/ConfirmDateOfBirth';
import GetReadyForVisit from './pages/GetReadyForVisit';
import Homepage from './pages/Homepage';
import { IOSCallEndedPage } from './pages/IOS/IOSCallEndedPage';
import { IOSPatientManageParticipantsPage } from './pages/IOS/IOSManageParticipantsPage';
import { IOSPatientPhotosEditPage } from './pages/IOS/IOSPatientPhotosEditPage';
import { IOSVideoCallMenu } from './pages/IOS/IOSVideoCallMenu';
import PaperworkPage from './pages/PaperworkPage';
import PastVisits from './pages/PastVisits';
import PatientCondition from './pages/PatientCondition';
import PatientInformation from './pages/PatientInformation';
import PaymentOption from './pages/PaymentOption';
import PersonAccompanying from './pages/PersonAccompanying';
import ReviewPaperwork from './pages/ReviewPaperwork';
import SelectPatient from './pages/SelectPatient';
import UserFlowRoot from './pages/UserFlowRoot';
import Version from './pages/Version';
import VideoChatPage from './pages/VideoChatPage';
import VisitDetails from './pages/VisitDetails';
import WaitingRoom from './pages/WaitingRoom';
import RequestVirtualVisit from './pages/RequestVirtualVisit';
import Welcome from './pages/Welcome';

const {
  MODE: environment,
  VITE_APP_FHIR_API_URL,
  VITE_APP_MIXPANEL_TOKEN,
  VITE_APP_PROJECT_API_URL,
  VITE_APP_SENTRY_DSN,
} = import.meta.env;

const isLowerEnvs = ['dev', 'testing', 'staging', 'training'].includes(environment);

if (isLowerEnvs || environment === 'production') {
  setupSentry({
    dsn: VITE_APP_SENTRY_DSN,
    environment,
    networkDetailAllowUrls: isLowerEnvs ? [VITE_APP_FHIR_API_URL, VITE_APP_PROJECT_API_URL] : [],
  });
}

const MIXPANEL_SETTINGS: MixpanelContextProps = {
  token: VITE_APP_MIXPANEL_TOKEN,
  registerProps: { appname: 'Telemed QRS' },
};

setupMixpanel(MIXPANEL_SETTINGS);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export class IntakeFlowPageRoute {
  static readonly Homepage = new IntakeFlowPageRoute('/home', <Homepage />);
  static readonly SelectPatient = new IntakeFlowPageRoute('/select-patient', <SelectPatient />);
  static readonly PastVisits = new IntakeFlowPageRoute('/past-visits', <PastVisits />);
  static readonly VisitDetails = new IntakeFlowPageRoute('/visit-details', <VisitDetails />);
  static readonly PatientInformation = new IntakeFlowPageRoute('/about-patient', <PatientInformation />);
  static readonly ConfirmDateOfBirth = new IntakeFlowPageRoute('/confirm-date-of-birth', <ConfirmDateOfBirth />);
  static readonly AuthPage = new IntakeFlowPageRoute('/auth', <AuthPage />);
  static readonly Welcome = new IntakeFlowPageRoute('/welcome', <Welcome />);
  static readonly GetReadyForVisit = new IntakeFlowPageRoute(
    '/paperwork/get-ready-for-the-visit',
    <GetReadyForVisit />
  );
  static readonly RequestVirtualVisit = new IntakeFlowPageRoute('/request-virtual-visit', <RequestVirtualVisit />);
  static readonly PatientCondition = new IntakeFlowPageRoute('/paperwork/patient-condition', <PatientCondition />);
  static readonly PaymentOption = new IntakeFlowPageRoute('/paperwork/payment-option', <PaymentOption />);
  static readonly PaperworkPage = new IntakeFlowPageRoute('/paperwork/:slug', <PaperworkPage />);
  static readonly PersonAccompanying = new IntakeFlowPageRoute(
    '/paperwork/person-accompanying-the-minor-patient',
    <PersonAccompanying />
  );
  static readonly WaitingRoom = new IntakeFlowPageRoute('/waiting-room', <WaitingRoom />);
  static readonly InvitedWaitingRoom = new IntakeFlowPageRoute('/invited-waiting-room', <WaitingRoom />);
  static readonly ReviewPaperwork = new IntakeFlowPageRoute('/review-paperwork', <ReviewPaperwork />);
  static readonly VideoCall = new IntakeFlowPageRoute('/video-call', <VideoChatPage />);
  static readonly InvitedVideoCall = new IntakeFlowPageRoute('/invited-video-call', <VideoChatPage />);
  static readonly CallEnded = new IntakeFlowPageRoute('/call-ended', <CallEndedPage />);
  static readonly InvitedCallEnded = new IntakeFlowPageRoute('/invited-call-ended', <CallEndedPage />);

  static readonly IOSPatientPhotosEdit = new IntakeFlowPageRoute('/ios-patient-photos', <IOSPatientPhotosEditPage />);
  static readonly IOSPatientManageParticipants = new IntakeFlowPageRoute(
    '/ios-manage-participants',
    <IOSPatientManageParticipantsPage />
  );
  static readonly IOSVideoCallMenu = new IntakeFlowPageRoute('/ios-video-call-menu', <IOSVideoCallMenu />);
  static readonly IOSCallEnded = new IntakeFlowPageRoute('/ios-call-ended', <IOSCallEndedPage />);

  private constructor(
    public readonly path: string,
    public readonly page: ReactElement
  ) {}
}

function App(): JSX.Element {
  useIOSAppSync();

  return (
    <QueryClientProvider client={queryClient}>
      <IntakeThemeProvider>
        <Router>
          <ScrollToTop />
          <ErrorAlert />
          <IOSMessagesHandler />
          <Routes>
            <Route path={'/version'} element={<Version />} />;
            <Route path={IntakeFlowPageRoute.AuthPage.path} element={IntakeFlowPageRoute.AuthPage.page} />
            <Route path={IntakeFlowPageRoute.Welcome.path} element={IntakeFlowPageRoute.Welcome.page} />
            <Route
              path={IntakeFlowPageRoute.InvitedWaitingRoom.path}
              element={IntakeFlowPageRoute.InvitedWaitingRoom.page}
            />
            <Route
              path={IntakeFlowPageRoute.InvitedVideoCall.path}
              element={IntakeFlowPageRoute.InvitedVideoCall.page}
            />
            <Route
              path={IntakeFlowPageRoute.InvitedCallEnded.path}
              element={IntakeFlowPageRoute.InvitedCallEnded.page}
            />
            <Route
              element={
                <ProtectedRoute
                  loadingFallback={<LoadingScreen />}
                  errorFallback={<ErrorFallbackScreen />}
                  unauthorizedFallback={<Navigate to={IntakeFlowPageRoute.Welcome.path} />}
                />
              }
            >
              <Route path="/" element={<UserFlowRoot />} />
              <Route
                path={IntakeFlowPageRoute.RequestVirtualVisit.path}
                element={IntakeFlowPageRoute.RequestVirtualVisit.page}
              />
              <Route path={IntakeFlowPageRoute.Homepage.path} element={IntakeFlowPageRoute.Homepage.page} />
              <Route path={IntakeFlowPageRoute.SelectPatient.path} element={IntakeFlowPageRoute.SelectPatient.page} />
              <Route path={IntakeFlowPageRoute.PastVisits.path} element={IntakeFlowPageRoute.PastVisits.page} />
              <Route path={IntakeFlowPageRoute.VisitDetails.path} element={IntakeFlowPageRoute.VisitDetails.page} />
              <Route
                path={IntakeFlowPageRoute.ConfirmDateOfBirth.path}
                element={IntakeFlowPageRoute.ConfirmDateOfBirth.page}
              />
              <Route
                path={IntakeFlowPageRoute.PatientInformation.path}
                element={IntakeFlowPageRoute.PatientInformation.page}
              />
              <Route path={IntakeFlowPageRoute.WaitingRoom.path} element={IntakeFlowPageRoute.WaitingRoom.page} />
              <Route
                path={IntakeFlowPageRoute.GetReadyForVisit.path}
                element={IntakeFlowPageRoute.GetReadyForVisit.page}
              />
              <Route
                path={IntakeFlowPageRoute.PatientCondition.path}
                element={IntakeFlowPageRoute.PatientCondition.page}
              />
              <Route path={IntakeFlowPageRoute.PaymentOption.path} element={IntakeFlowPageRoute.PaymentOption.page} />
              <Route path={IntakeFlowPageRoute.PaperworkPage.path} element={IntakeFlowPageRoute.PaperworkPage.page} />
              <Route
                path={IntakeFlowPageRoute.PersonAccompanying.path}
                element={IntakeFlowPageRoute.PersonAccompanying.page}
              />
              <Route
                path={IntakeFlowPageRoute.ReviewPaperwork.path}
                element={IntakeFlowPageRoute.ReviewPaperwork.page}
              />
              <Route path={IntakeFlowPageRoute.VideoCall.path} element={IntakeFlowPageRoute.VideoCall.page} />
              <Route path={IntakeFlowPageRoute.CallEnded.path} element={IntakeFlowPageRoute.CallEnded.page} />
            </Route>
            {/* TODO: make IOS routes be under protected route but without custom container */}
            <Route
              path={IntakeFlowPageRoute.IOSPatientPhotosEdit.path}
              element={IntakeFlowPageRoute.IOSPatientPhotosEdit.page}
            />
            <Route
              path={IntakeFlowPageRoute.IOSPatientManageParticipants.path}
              element={IntakeFlowPageRoute.IOSPatientManageParticipants.page}
            />
            <Route
              path={IntakeFlowPageRoute.IOSVideoCallMenu.path}
              element={IntakeFlowPageRoute.IOSVideoCallMenu.page}
            />
            <Route path={IntakeFlowPageRoute.IOSCallEnded.path} element={IntakeFlowPageRoute.IOSCallEnded.page} />
            <Route path="*" element={<Navigate to={IntakeFlowPageRoute.Welcome.path} />} />
          </Routes>
        </Router>
      </IntakeThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
