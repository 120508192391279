import { useQuery } from 'react-query';
import { ApiError } from 'ui-components/lib/types/data';
import { GetEligibilityInsurance, GetEligibilityPolicyHolder, GetEligibilityResponse } from 'utils';
import { useZapEHRAPIClient } from '../../utils';
import { useAppointmentStore } from '../appointments';
import { usePaperworkStore } from '../paperwork';
import { usePatientInfoStore } from '../patient-info';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useCheckEligibilityQuery = (
  onSuccess: (data: GetEligibilityResponse) => void,
  onError: (message: string) => void,
  secondary: boolean
) => {
  const apiClient = useZapEHRAPIClient();
  const patientId = usePatientInfoStore((state) => state.patientInfo.id);
  const appointmentId = useAppointmentStore((state) => state.appointmentID);

  return useQuery(
    ['insurance-check', patientId, { apiClient }],
    async () => {
      if (!apiClient || !appointmentId || !patientId) {
        throw new Error('App client, appointment ID, or patient ID is not provided.');
      }
      const { completedPaperwork } = usePaperworkStore.getState();
      const insurance: GetEligibilityInsurance = {
        insuranceId: completedPaperwork['insurance-carrier'],
        memberId: completedPaperwork['insurance-member-id'],
        additionalInfo: completedPaperwork['insurance-additional-information'] || undefined,
      };
      const policyHolder: GetEligibilityPolicyHolder = {
        // TODO post MVP
        isPatient: false, //completedPaperwork['insurance-subscriber-is-patient'],
        firstName: completedPaperwork['policy-holder-first-name'] || undefined,
        middleName: completedPaperwork['policy-holder-middle-name'] || undefined,
        lastName: completedPaperwork['policy-holder-last-name'] || undefined,
        dob: completedPaperwork['policy-holder-date-of-birth'] || undefined,
        sex: completedPaperwork['policy-holder-birth-sex'] || undefined,
        addressSameAsPatient: completedPaperwork['policy-holder-address-as-patient'],
        address: completedPaperwork['policy-holder-address'] || undefined,
        city: completedPaperwork['policy-holder-city'] || undefined,
        state: completedPaperwork['policy-holder-state'] || undefined,
        zip: completedPaperwork['policy-holder-zip'] || undefined,
        relationship: completedPaperwork['patient-relationship-to-insured'] || undefined,
      };

      let secondaryInsurance: GetEligibilityInsurance | undefined = undefined;
      let secondaryPolicyHolder: GetEligibilityPolicyHolder | undefined = undefined;

      if (secondary) {
        secondaryInsurance = {
          insuranceId: completedPaperwork['insurance-carrier-2'],
          memberId: completedPaperwork['insurance-member-id-2'],
          additionalInfo: completedPaperwork['insurance-additional-information-2'] || undefined,
        };
        secondaryPolicyHolder = {
          // TODO post MVP
          isPatient: false, //completedPaperwork['insurance-subscriber-is-patient-2'],
          firstName: completedPaperwork['policy-holder-first-name-2'] || undefined,
          middleName: completedPaperwork['policy-holder-middle-name-2'] || undefined,
          lastName: completedPaperwork['policy-holder-last-name-2'] || undefined,
          dob: completedPaperwork['policy-holder-date-of-birth-2'] || undefined,
          sex: completedPaperwork['policy-holder-birth-sex-2'] || undefined,
          addressSameAsPatient: completedPaperwork['policy-holder-address-as-patient-2'],
          address: completedPaperwork['policy-holder-address-2'] || undefined,
          city: completedPaperwork['policy-holder-city-2'] || undefined,
          state: completedPaperwork['policy-holder-state-2'] || undefined,
          zip: completedPaperwork['policy-holder-zip-2'] || undefined,
          relationship: completedPaperwork['patient-relationship-to-insured-2'] || undefined,
        };
      }

      return await apiClient.getEligibility({
        appointmentId,
        insurance,
        patientId,
        policyHolder,
        secondaryInsurance,
        secondaryPolicyHolder,
      });
    },
    {
      enabled: false,
      onSuccess,
      onError: (err: ApiError) => {
        console.error('Error during fetching check insurance methods: ', err);
        onError(err.message);
      },
      retry: false,
    }
  );
};
