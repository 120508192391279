import { PatientInfo } from 'utils';

const fillingOutAsMapper = {
  'Parent/Guardian': 'Parent/Guardian',
  Patient: 'Patient (Self)',
};

export const modifyPaperworkOnLoad = ({ patientInfo }: { patientInfo: PatientInfo }): any => {
  const paperwork: any = {};

  if (patientInfo.emailUser === 'Patient') {
    paperwork['patient-email'] = patientInfo.email;
  }
  if (patientInfo.emailUser === 'Parent/Guardian') {
    paperwork['guardian-email'] = patientInfo.email;
  }

  if (patientInfo.emailUser) {
    paperwork['patient-filling-out-as'] = fillingOutAsMapper[patientInfo.emailUser];
  }

  return paperwork;
};
