import { FieldValues } from 'react-hook-form';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { zustandDevtools } from '../../utils';

interface EligibilityState {
  formValues: FieldValues;
  tries: number;
  eligible?: boolean;
}

const ELIGIBILITY_STATE_INITIAL: EligibilityState = {
  formValues: {},
  tries: 0,
};

interface EligibilityStateActions {
  setState: (state: Partial<EligibilityState>) => void;
}

export const useEligibilityStore = create<EligibilityState & EligibilityStateActions>()(
  persist((set) => ({ ...ELIGIBILITY_STATE_INITIAL, setState: (state) => set({ ...state }) }), {
    name: 'telemed-eligibility-storage',
  })
);

zustandDevtools('Telemed eligibility', useEligibilityStore);
