import { FC, useCallback, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageForm, formInputStringToBoolean } from 'ui-components';
import { getPatientChosenName, getSelectors } from 'utils';
import { IntakeFlowPageRoute } from '../App';
import { CustomContainer } from '../features/common';
import { usePaperworkStore } from '../features/paperwork';
import { usePatientInfoStore } from '../features/patient-info';
import { useMapQuestionsToFormInputFields, usePaperworkPageInfo } from '../utils';

const PersonAccompanying: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { paperworkQuestions, patchCompletedPaperwork } = getSelectors(usePaperworkStore, [
    'paperworkQuestions',
    'patchCompletedPaperwork',
  ]);
  const { completedPaperwork } = usePaperworkStore.getState();
  const { patientInfo } = getSelectors(usePatientInfoStore, ['patientInfo']);

  const {
    items: itemsTemp,
    nextPage,
    pageName,
    currentPage,
    currentIndex,
  } = usePaperworkPageInfo({
    location,
    paperworkQuestions,
  });
  const items = itemsTemp.map((item) => ({ ...item, requireWhen: undefined, required: true }));

  const onSubmit = useCallback(
    async (data: FieldValues): Promise<void> => {
      if (!paperworkQuestions) {
        throw new Error('paperworkQuestions is not defined');
      }

      // Update completed paperwork state
      formInputStringToBoolean(data, items);
      patchCompletedPaperwork(data);

      if (currentIndex === paperworkQuestions.length - 1) {
        navigate(IntakeFlowPageRoute.ReviewPaperwork.path);
      } else {
        navigate(`/paperwork/${nextPage?.slug || ''}`);
      }
    },
    [paperworkQuestions, items, patchCompletedPaperwork, currentIndex, navigate, nextPage?.slug]
  );

  const mapQuestionsToFormInputFields = useMapQuestionsToFormInputFields({
    getLabel: (item) => item.text.replace('{patientFirstName}', getPatientChosenName(patientInfo)),
    getDefaultValue: (item) => completedPaperwork[item.id],
  });
  const formElements = useMemo(() => mapQuestionsToFormInputFields(items), [mapQuestionsToFormInputFields, items]);

  const onFormValuesChange = useCallback(
    (formValues: FieldValues): void => {
      patchCompletedPaperwork(formValues);
    },
    [patchCompletedPaperwork]
  );

  return (
    <CustomContainer
      title={pageName}
      description={items[0]?.type === 'Description' ? items[0]?.text : undefined}
      bgVariant={currentPage.slug}
    >
      <PageForm formElements={formElements} onSubmit={onSubmit} onFormValuesChange={onFormValuesChange} />
    </CustomContainer>
  );
};

export default PersonAccompanying;
