import { EditOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  IconButton,
  Link as MuiLink,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import Pending from '@mui/icons-material/Pending';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { CustomDialog, PageForm, ReviewItem, getValueBoolean, safelyCaptureException } from 'ui-components';
import { deepCopy, getPatientInfoFullNameUsingChosen, getSelectors, yupDateTransform, yupFHIRDateRegex } from 'utils';
import { IntakeFlowPageRoute } from '../App';
import { otherColors } from '../IntakeThemeProvider';
import { useAppointmentStore, useUpdateAppointmentMutation } from '../features/appointments';
import { CustomContainer, useIntakeCommonStore } from '../features/common';
import { useFilesStore } from '../features/files';
import { useCreatePaperworkMutation, usePaperworkStore } from '../features/paperwork';
import { usePatientInfoStore } from '../features/patient-info';
import { useCreateInviteMutation } from '../features/waiting-room';
import { useOpenExternalLink } from '../hooks/useOpenExternalLink';
import { useZapEHRAPIClient } from '../utils';
import { isPaperworkPageComplete } from '../utils/paperworkCompleted';
import { LocationError, useTelemedLocation } from '../features/locationState/useTelemedLocation';

const ReviewPaperwork = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { appointmentID } = getSelectors(useAppointmentStore, ['appointmentID']);
  const fileURLs = useFilesStore((state) => state.fileURLs);
  const openExternalLink = useOpenExternalLink();
  const { patientInfo } = usePatientInfoStore.getState();
  const createPaperwork = useCreatePaperworkMutation();
  const apiClient = useZapEHRAPIClient();
  const createInviteMutation = useCreateInviteMutation();
  const [showStateErrorDialog, setShowStateErrorDialog] = useState(false);
  const [telemedLocationError, setTelemedLocationError] = useState<LocationError | undefined>();

  const updateAppointmentMutation = useUpdateAppointmentMutation();

  const {
    location: telemedLocation,
    isValidationRunning,
    isLocationInitialized,
    validateAppointmentLocation,
  } = useTelemedLocation();

  const { completedPaperwork, paperworkQuestions } = getSelectors(usePaperworkStore, [
    'completedPaperwork',
    'paperworkQuestions',
  ]);

  const completedPaperworkDefined = completedPaperwork || {};
  const paperworkQuestionsDefined = paperworkQuestions || [];

  const resetAndNavigate = (error: boolean): void => {
    usePaperworkStore.setState({ paperworkQuestions: undefined, completedPaperwork: undefined });
    useFilesStore.setState({ fileURLs: undefined, fileUploads: {} });
    navigate(`${IntakeFlowPageRoute.WaitingRoom.path}?appointment_id=${appointmentID}`, {
      state: { inviteErrorSnackbarOpen: error },
    });
  };

  const onSubmit = async (): Promise<void> => {
    if (!apiClient) {
      throw new Error('apiClient is not defined');
    }

    if (!appointmentID) {
      throw new Error('appointmentID is not defined');
    }

    if (!paperworkQuestions) {
      throw new Error('paperworkQuestions is not defined');
    }

    if (!completedPaperwork) {
      throw new Error('paperwork is not defined');
    }

    const { error } = await validateAppointmentLocation();

    if (error) {
      setTelemedLocationError(error);
      setShowStateErrorDialog(true);
      return;
    }

    const normalizedData = deepCopy(completedPaperwork);

    // remove characters that are not numbers from phone numbers
    Object.keys(normalizedData).forEach((attribute) => {
      let phoneNumber = false;
      let date = false;
      paperworkQuestions?.forEach((questionPage) => {
        questionPage.questions
          .filter((itemTemp) => normalizedData[itemTemp.id] !== '')
          .forEach((itemTemp) => {
            if (itemTemp.id === attribute) {
              if (itemTemp.format === 'Phone Number') {
                phoneNumber = true;
                return;
              }
              if (itemTemp.type === 'Date') {
                date = true;
                return;
              }
            }
          });
      });
      if (phoneNumber) {
        normalizedData[attribute] = normalizedData[attribute].replace(/\D/g, '');
      }

      if (date) {
        if (!yupFHIRDateRegex.test(normalizedData[attribute])) {
          if (typeof normalizedData[attribute] === 'object') {
            normalizedData[attribute] = yupDateTransform(normalizedData[attribute]);
          }
        }
      }
    });

    createPaperwork.mutate(
      {
        apiClient,
        appointmentID: appointmentID,
        paperwork: completedPaperwork,
        files: fileURLs,
        patientId: patientInfo.id || '',
      },
      {
        onSuccess: async () => {
          if (normalizedData['invite-from-another-device'] === 'Yes, I will add invite details below') {
            const inviteParams = {
              firstName: normalizedData['invite-first'],
              lastName: normalizedData['invite-last'],
              emailAddress: normalizedData['invite-email'],
              phoneNumber: normalizedData['invite-phone'],
            };

            createInviteMutation.mutate(inviteParams, {
              onSuccess: () => {
                resetAndNavigate(false);
              },
              onError: async () => {
                resetAndNavigate(true);
              },
            });
          } else {
            resetAndNavigate(false);
          }
        },
        onError: (error: any) => {
          safelyCaptureException(error);
          useIntakeCommonStore.setState({ error: t('general.errors.general') });
        },
      }
    );
  };

  // fire only on mount
  useEffect(() => {
    const { completedPaperwork, paperworkQuestions } = usePaperworkStore.getState();
    if (!completedPaperwork || !paperworkQuestions) {
      navigate(IntakeFlowPageRoute.Homepage.path);
    }
  }, [navigate]);

  let reviewItems: ReviewItem[] = [
    {
      name: 'Patient',
      valueString: getPatientInfoFullNameUsingChosen(patientInfo),
      hidden: !patientInfo?.firstName, // users who are not logged in will not see name
    },
    {
      name: 'State',
      ...(isLocationInitialized && !updateAppointmentMutation.isLoading
        ? { valueString: telemedLocation?.state }
        : { isPending: true }),
      path: '/request-virtual-visit',
    },
    ...paperworkQuestionsDefined.map((paperworkPage) => ({
      name: paperworkPage.reviewPageName || paperworkPage.page,
      path: `/paperwork/${paperworkPage.slug}`,
      valueBoolean: isPaperworkPageComplete(completedPaperworkDefined, paperworkPage, fileURLs),
    })),
  ];
  if (completedPaperworkDefined['get-ready-for-the-visit-filling-out-as'] === 'I am the patient') {
    reviewItems = reviewItems.filter((reviewItem) => reviewItem.name !== 'Person accompanying the minor patient');
  }

  return (
    <CustomContainer
      title="Review and submit"
      description="Review and confirm all details below."
      bgVariant={IntakeFlowPageRoute.ReviewPaperwork.path}
    >
      <Typography variant="h3" color="primary" marginTop={2} marginBottom={0}>
        Visit details
      </Typography>
      <Table
        sx={{
          marginBottom: 2,
          tr: {
            td: {
              borderBottom: '1px solid #E3E6EF',
            },
            '&:last-child': {
              td: {
                borderBottom: 'none',
              },
            },
          },
        }}
      >
        <TableBody>
          {reviewItems
            .filter((reviewItem) => !reviewItem.hidden)
            .map((reviewItem) => (
              <TableRow key={reviewItem.name}>
                <TableCell
                  sx={{
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 0,
                    paddingRight: 0,
                    color: otherColors.darkPurple,
                  }}
                >
                  {reviewItem.name}
                </TableCell>
                <TableCell padding="none" align="right">
                  {reviewItem.isPending && (
                    <Chip
                      icon={<Pending />}
                      size="small"
                      color="info"
                      sx={{
                        fontSize: '14px',
                        padding: '4px',
                        '.MuiChip-icon': { color: otherColors.darkGreen, margin: 0 },
                        '.MuiChip-label': { display: 'none' },
                      }}
                    />
                  )}
                  {reviewItem.valueString !== undefined && reviewItem.valueString}
                  {reviewItem.valueBoolean !== undefined && getValueBoolean(reviewItem.valueBoolean)}
                </TableCell>
                <TableCell padding="none" sx={{ paddingLeft: 1 }}>
                  {reviewItem.path && (
                    <Tooltip title="Edit" placement="right">
                      <Link to={reviewItem.path}>
                        <IconButton aria-label="edit" color="primary">
                          <EditOutlined />
                        </IconButton>
                      </Link>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Typography variant="body2">
        By proceeding with a visit, you acknowledge that you have reviewed and accept our{' '}
        <MuiLink
          sx={{ cursor: 'pointer' }}
          onClick={() => openExternalLink('https://pmpediatriccare.com/privacy-policy')}
          target="_blank"
        >
          Privacy Policy
        </MuiLink>{' '}
        and{' '}
        <MuiLink
          sx={{ cursor: 'pointer' }}
          onClick={() => openExternalLink('https://pmpediatriccare.com/terms-and-conditions')}
          target="_blank"
        >
          Terms and Conditions of Service
        </MuiLink>
        .
      </Typography>
      <PageForm
        controlButtons={{
          submitLabel: 'Go to the Wait Room',
          loading:
            createPaperwork.isLoading ||
            createInviteMutation.isLoading ||
            updateAppointmentMutation.isLoading ||
            isValidationRunning,
        }}
        onSubmit={onSubmit}
      />
      {showStateErrorDialog && telemedLocationError && (
        <CustomDialog
          PaperProps={{ sx: { borderRadius: 2 } }}
          open={true}
          onClose={() => setShowStateErrorDialog(false)}
        >
          <Typography variant="h2" color="primary.main" sx={{ pb: 3 }}>
            {telemedLocationError?.title}
          </Typography>
          <Typography variant="body1" sx={{ pb: 3 }}>
            {telemedLocationError?.content}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" onClick={() => setShowStateErrorDialog(false)}>
              Ok
            </Button>
          </Box>
        </CustomDialog>
      )}
    </CustomContainer>
  );
};

export default ReviewPaperwork;
