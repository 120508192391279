import { Select, MenuItem, InputLabel, FormControl, Grid, InputBaseProps, useMediaQuery, Input } from '@mui/material';
import { RefCallBack } from 'react-hook-form';
import { months } from 'utils';
import { breakpoints } from '../../providers/IntakeThemeProviderBase';
import { BoldPurpleInputLabel } from './BoldPurpleInputLabel';

interface DateInputFieldProps extends InputBaseProps {
  selectedMonth: string;
  selectedDay: string;
  selectedYear: string;
  required?: boolean;
  label?: string;
  error?: boolean;
  setSelectedMonth: (month: string) => void;
  setSelectedYear: (year: string) => void;
  setSelectedDay: (day: string) => void;
  inputRef?: RefCallBack;
  dayRef?: RefCallBack;
  monthRef?: RefCallBack;
  yearRef?: RefCallBack;
}

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const startYear = 1900;

const days = Array.from({ length: 31 }, (_, index) => {
  const day: number = index + 1;
  return { value: day < 10 ? `0${day}` : `${day}`, label: `${day}` };
});

const years = Array.from({ length: currentYear - startYear + 1 }, (_, index) => {
  const year: number = startYear + index;
  return { value: `${year}`, label: `${year}` };
}).reverse();

const DateInputFields = ({
  required,
  selectedMonth,
  selectedDay,
  selectedYear,
  label,
  error,
  inputRef, // to facilitate scroll-into-frame on error
  monthRef,
  yearRef,
  dayRef,
  setSelectedMonth,
  setSelectedYear,
  setSelectedDay,
  disabled,
}: DateInputFieldProps): JSX.Element => {
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.values?.sm}px)`);
  return (
    <FormControl required={required} sx={{ width: '100%' }} hiddenLabel={label === undefined}>
      {inputRef && <Input hidden={false} inputRef={inputRef} sx={{ height: '0px', width: '0px' }} />}
      {label && (
        <BoldPurpleInputLabel shrink key="input_label">
          {label}
        </BoldPurpleInputLabel>
      )}
      <Grid
        container
        mt={label ? 2 : 0}
        spacing={2}
        rowGap={2}
        direction={isMobile ? 'column' : 'row'}
        key="input_grid"
        sx={{
          '& .MuiGrid-item': {
            marginTop: isMobile ? 2 : 0,
            paddingTop: 0,
          },
        }}
      >
        <Grid item md={4} xs={12}>
          <FormControl required={required} sx={{ width: '100%' }} hiddenLabel={true} disabled={disabled}>
            <InputLabel>Month</InputLabel>
            <Select
              label="Month"
              inputRef={monthRef}
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
              error={error}
            >
              {months.map((month) => (
                <MenuItem key={`month-${month.value}`} value={month.value}>
                  {month.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={4} xs={12}>
          <FormControl required={required} sx={{ width: '100%' }} hiddenLabel={true} disabled={disabled}>
            <InputLabel>Day</InputLabel>
            <Select
              label="Day"
              inputRef={dayRef}
              error={error}
              value={selectedDay}
              onChange={(e) => setSelectedDay(e.target.value)}
            >
              {days.map((day) => (
                <MenuItem key={`day-${day.value}`} value={day.value}>
                  {day.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={4} xs={12}>
          <FormControl required={required} sx={{ width: '100%' }} hiddenLabel={true} disabled={disabled}>
            <InputLabel>Year</InputLabel>
            <Select
              label="Year"
              inputRef={yearRef}
              value={selectedYear}
              error={error}
              onChange={(e: any) => setSelectedYear?.(e.target.value)}
            >
              {years.map((year) => (
                <MenuItem key={`year-${year.value}`} value={year.value}>
                  {year.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default DateInputFields;
