import AddIcon from '@mui/icons-material/Add';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Card, FormControlLabel, Radio, RadioGroup, Skeleton, Typography, useTheme } from '@mui/material';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { DateTime } from 'luxon';
import { FC, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { FieldValues } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useBeforeUnload, useLocation, useNavigate } from 'react-router-dom';
import {
  BoldPurpleInputLabel,
  ErrorDialog,
  FormInputType,
  FormInputTypeField,
  PageForm,
  filterObject,
  formInputStringToBoolean,
} from 'ui-components';
import { PAGE_FORM_INNER_FORM_ID } from 'ui-components/lib/components/PageForm';
import {
  CreditCardInfo,
  FileURLs,
  InsurancePlanDTO,
  InsurancePlanRequirementKeys,
  getPatientChosenName,
  getSelectors,
} from 'utils';
import { IntakeFlowPageRoute } from '../App';
import { otherColors } from '../IntakeThemeProvider';
import { useAppointmentStore } from '../features/appointments';
import { CustomContainer } from '../features/common';
import { useCheckEligibilityQuery, useEligibilityStore } from '../features/eligibility';
import { useCreateZ3ObjectMutation, useFilesStore } from '../features/files';
import {
  useDeletePaymentMethod,
  useGetPaymentMethods,
  useInsurancesQuery,
  usePaperworkStore,
  useSetDefaultPaymentMethod,
  useSetupPaymentMethod,
} from '../features/paperwork';
import { usePatientInfoStore } from '../features/patient-info';
import { useMapQuestionsToFormInputFields, usePaperworkPageInfo, useZapEHRAPIClient } from '../utils';

const POLICY_HOLDER_FIELDS = [
  'policy-holder-address',
  'policy-holder-city',
  'policy-holder-state',
  'policy-holder-zip',
] as const;

const mapPolicyHolderFieldToPatientField = {
  'policy-holder-address': 'patient-street-address',
  'policy-holder-city': 'patient-city',
  'policy-holder-state': 'patient-state',
  'policy-holder-zip': 'patient-zip',
} as const;

function isPatientAddressesEqual(prevStepValues: any, paymentsRelatedValues: any, index: string): boolean {
  const props = [...POLICY_HOLDER_FIELDS];
  for (const prop of props) {
    if (paymentsRelatedValues[`${prop}${index}`] !== prevStepValues[mapPolicyHolderFieldToPatientField[prop]]) {
      return false;
    }
  }
  return true;
}

const updateFormFieldsRequiredState = (insurances: InsurancePlanDTO[], mapped: FormInputTypeField[]): void => {
  const requiredState: Record<InsurancePlanRequirementKeys, string[]> = {
    requiresSubscriberId: [],
    requiresSubscriberName: [],
    requiresSubscriberDOB: [],
    requiresRelationshipToSubscriber: [],
    requiresInsuranceName: [],
    requiresInsuranceCardImage: [],
    requiresFacilityNPI: [],
    requiresStateUID: [],
    enabledEligibilityCheck: [],
  };

  insurances.forEach((insurance) => {
    Object.keys(requiredState).forEach((key) => {
      // Despite using the Record above, we still have to typecast to silence TS
      const eligibilityKey = key as InsurancePlanRequirementKeys;
      if (insurance[eligibilityKey] && insurance.id) {
        requiredState[eligibilityKey].push(insurance.id);
      }
    });
  });

  const addRequireRule = (requiredFieldInsurances: string[], item: FormInputType, fieldName: string): void => {
    if (requiredFieldInsurances.length > 0 && item.name.startsWith(fieldName)) {
      item.requireWhen = {
        question: `insurance-carrier${item.name === fieldName ? '' : '-2'}`,
        operator: 'contains',
        answer: requiredFieldInsurances,
      };
    }
  };

  mapped.forEach((item) => {
    addRequireRule(requiredState.requiresSubscriberId, item, 'insurance-member-id');
    addRequireRule(requiredState.requiresSubscriberName, item, 'policy-holder-first-name');
    addRequireRule(requiredState.requiresSubscriberName, item, 'policy-holder-last-name');
    addRequireRule(requiredState.requiresSubscriberDOB, item, 'policy-holder-date-of-birth');
    addRequireRule(requiredState.requiresRelationshipToSubscriber, item, 'patient-relationship-to-insured');
    addRequireRule(requiredState.requiresInsuranceCardImage, item, 'insurance-card-front');
    addRequireRule(requiredState.requiresInsuranceCardImage, item, 'insurance-card-back');
  });

  ['', '-2'].forEach((index) =>
    mapped
      .filter((item) => POLICY_HOLDER_FIELDS.map((field) => `${field}${index}`).includes(item.name))
      .forEach((item) => {
        item.disableWhen = {
          question: `policy-holder-address-as-patient${index}`,
          operator: '=',
          answer: true,
        };
      })
  );
};

const CREDIT_CARD_ITEM_ID = 'credit-card-id';
const ADD_NEW_CARD_OPTION_VALUE = 'add-new-card';
const DEFAULT_ERROR_DETAILS = {
  title: 'An error occurred',
  message:
    'Please confirm your information and try again. If the error persists, contact PM Pediatrics support (516) 207-7950.',
  closeButtonText: 'Close',
};

const PaymentOption = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();

  const apiClient = useZapEHRAPIClient();
  const createZ3Object = useCreateZ3ObjectMutation();
  const { data } = useInsurancesQuery();
  const insurances = useMemo(() => data?.insurances ?? [], [data?.insurances]);

  const { paperworkQuestions, patchCompletedPaperwork } = getSelectors(usePaperworkStore, [
    'paperworkQuestions',
    'patchCompletedPaperwork',
  ]);
  const { completedPaperwork } = usePaperworkStore.getState();
  const { patientInfo } = getSelectors(usePatientInfoStore, ['patientInfo']);
  const { appointmentID } = getSelectors(useAppointmentStore, ['appointmentID']);
  const { fileURLs, patchFileURLs, fileUploads, setFileUploads } = getSelectors(useFilesStore, [
    'fileURLs',
    'patchFileURLs',
    'fileUploads',
    'setFileUploads',
  ]);
  const [showSecond, setShowSecond] = useState(!!completedPaperwork['insurance-carrier-2']);
  const [creditCardError, setCreditCardError] = useState(false);
  const [isValidCardChosen, setValidCardChosen] = useState<boolean>(false);
  const [areCardsLoading, setAreCardsLoading] = useState(false);

  const { items, nextPage, pageName, currentPage, currentIndex } = usePaperworkPageInfo({
    location,
    paperworkQuestions,
  });

  // File upload variables
  const fileItems = items.filter((item) => item.type === 'File');
  const allFileKeys = Object.keys(fileUploads);

  // Create object keys for each file input using the input name if the key doesn't already exist
  useEffect(() => {
    if (fileItems.length > 0) {
      fileItems.forEach((fileItem) => {
        !(fileItem.id in fileUploads) &&
          setFileUploads((prev) => ({ ...prev, [fileItem.id]: { fileData: null, uploadFailed: false } }));
      });
    }
  }, [fileItems, fileUploads, setFileUploads]);

  useBeforeUnload(() => {
    const localURLsReset: FileURLs = {};
    Object.keys(fileURLs || {}).forEach((fileKey) => {
      localURLsReset[fileKey] = { ...fileURLs?.[fileKey], localUrl: undefined };
    });
    patchFileURLs(localURLsReset);
    setFileUploads({});
  });

  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorDialogDetails, setErrorDialogDetails] = useState(DEFAULT_ERROR_DETAILS);
  const {
    isFetching: isEligibilityFetching,
    isLoading: isEligibilityLoading,
    refetch: refetchEligibility,
  } = useCheckEligibilityQuery(
    async (data) => {
      useEligibilityStore.setState({ eligible: data.eligible });
    },
    (message: string) => {
      useEligibilityStore.setState({ eligible: undefined });
      if (message === 'Internal error') {
        setErrorDialogDetails(DEFAULT_ERROR_DETAILS);
      } else if (message.startsWith('Parameter ')) {
        setErrorDialogDetails({
          ...DEFAULT_ERROR_DETAILS,
          message: 'Missing required information.',
        });
      } else {
        useEligibilityStore.setState((state) => ({ tries: state.tries + 1 }));
        setErrorDialogDetails({
          ...DEFAULT_ERROR_DETAILS,
          message,
        });
      }
      setErrorDialogOpen(true);
    },
    showSecond
  );

  const onSubmit = useCallback(
    async (data: FieldValues): Promise<void> => {
      if (!paperworkQuestions) {
        throw new Error('paperworkQuestions is not defined');
      }

      const latestCompletedPaperwork = usePaperworkStore.getState().completedPaperwork;

      if (data['payment-option'] === 'Self-pay') {
        if (!latestCompletedPaperwork[CREDIT_CARD_ITEM_ID] || !isValidCardChosen) {
          setCreditCardError(true);
          return;
        }
      } else {
        patchCompletedPaperwork({ [CREDIT_CARD_ITEM_ID]: undefined });
        if (!latestCompletedPaperwork['insurance-carrier-2']) {
          data['insurance-carrier-2'] = undefined;
        }

        await refetchEligibility();

        const { eligible } = useEligibilityStore.getState();
        if (eligible) {
          useEligibilityStore.setState({ tries: 0 });
        } else if (eligible === false) {
          useEligibilityStore.setState((state) => ({ tries: state.tries + 1 }));

          const message =
            "We were unable to verify insurance eligibility. Please select 'try again' to  confirm the information was entered correctly";
          setErrorDialogDetails({
            title: 'Coverage not found',
            message:
              useEligibilityStore.getState().tries > 2
                ? `${message} or contact support (516) 207-7950.`
                : `${message}.`,
            closeButtonText: 'Try again',
          });
          setErrorDialogOpen(true);
        }

        if (!eligible) return;
      }

      // Upload files to z3 if the page includes File type input
      if (fileItems.length > 0) {
        let uploadResponse: any;

        for (const fileItem of fileItems) {
          const fileId = fileItem.id;
          const fileData = fileUploads[fileId].fileData;

          if (appointmentID && fileData) {
            await createZ3Object.mutateAsync(
              {
                apiClient,
                fileType: fileId,
                fileFormat: fileData?.type.split('/')[1],
                file: fileData,
                appointmentID,
              },
              {
                onSuccess: (response) => {
                  uploadResponse = response;
                },
                onError: (error) => {
                  throw error;
                },
              }
            );
          }

          if (fileData && !uploadResponse) {
            // Reset fields if Z3 upload fails
            setFileUploads((prev) => ({
              ...prev,
              [fileId]: { fileData: null, uploadFailed: true },
            }));
            data[fileId] = undefined;
            return;
          } else if (fileData && uploadResponse) {
            data[fileId] = uploadResponse.z3URL;
            // Reset file data when user continues to next  page
            setFileUploads((prev) => ({
              ...prev,
              [fileId]: { fileData: null, uploadFailed: prev[fileId].uploadFailed },
            }));
          } else {
            // Reset state for files because upload blob url overwrites Front and Back Url data
            // Only when cards are not being cleared
            if (data[fileId]) {
              data[fileId] = fileURLs?.[fileId]?.z3Url;
            }
          }
        }
      }

      // Update state.fileURLs
      if (fileItems.length > 0) {
        const formFileKeys = fileItems.map((item) => item.id);
        const fileUploadData: any = filterObject(data, (key) => formFileKeys.includes(key));
        const fileURLsUpdated: FileURLs = {};
        formFileKeys.forEach((key) => {
          fileURLsUpdated[key] = {
            ...fileURLs?.[key],
            z3Url: fileUploadData[key],
            localUrl: fileUploadData[key] ? fileURLs?.[key].localUrl : undefined, // Reset localUrl if no data[fileId]
          };
        });
        patchFileURLs(fileURLsUpdated);
      }

      // Update completed paperwork state
      // Filter out file data
      formInputStringToBoolean(data, items);
      const paperworkData = filterObject(data, (key) => !allFileKeys.includes(key));
      patchCompletedPaperwork(paperworkData);

      if (currentIndex === paperworkQuestions.length - 1) {
        navigate(IntakeFlowPageRoute.ReviewPaperwork.path);
      } else {
        navigate(`/paperwork/${nextPage?.slug || ''}`);
      }
    },
    [
      allFileKeys,
      apiClient,
      appointmentID,
      createZ3Object,
      currentIndex,
      fileItems,
      fileURLs,
      fileUploads,
      isValidCardChosen,
      items,
      navigate,
      nextPage?.slug,
      paperworkQuestions,
      patchCompletedPaperwork,
      patchFileURLs,
      refetchEligibility,
      setFileUploads,
    ]
  );

  const mapQuestionsToFormInputFields = useMapQuestionsToFormInputFields({
    getLabel: (item) => item.text.replace('{patientFirstName}', getPatientChosenName(patientInfo)),
    getDefaultValue: (item) =>
      fileURLs?.[item.id]
        ? fileURLs?.[item.id]?.localUrl || fileURLs?.[item.id]?.presignedUrl
        : completedPaperwork[item.id],
    getFileOptions: (item) => ({
      description: item.attachmentText,
      onUpload: setFileUploads,
      uploadFile: (fileType: string, tempURL: string) =>
        patchFileURLs({ [fileType]: { ...fileURLs?.[fileType], localUrl: tempURL } }),
      uploadFailed: fileUploads[item.id]?.uploadFailed,
      resetUploadFailed: () =>
        setFileUploads((prev) => ({
          ...prev,
          [item.id]: { ...prev[item.id], uploadFailed: false },
        })),
      onClear: () => {
        setFileUploads((prev) => ({
          ...prev,
          [item.id]: { ...prev[item.id], fileData: null },
        }));
        patchFileURLs({
          [item.id]: { localUrl: undefined, presignedUrl: undefined, z3Url: undefined },
        });
      },
      fileType: item.id,
    }),
    getSelectOptions: (item) =>
      item.id.startsWith('insurance-carrier')
        ? insurances.map((insurance) => ({
            label: insurance.name || 'Unknown',
            value: insurance.id || 'Unknown',
          })) ||
          (completedPaperwork[item.id]
            ? [{ label: completedPaperwork[item.id], value: completedPaperwork[item.id] }]
            : [])
        : item.options,
  });

  const updateAddress = useCallback((formValues: FieldValues, setValue: (name: string, value: any) => void): void => {
    const prevValues = usePaperworkStore.getState().completedPaperwork;

    ['', '-2'].forEach((index) => {
      if (
        formValues[`policy-holder-address-as-patient${index}`] === true &&
        ([false, undefined].includes(prevValues[`policy-holder-address-as-patient${index}`]) ||
          // check if address data was updated on previous pages
          !isPatientAddressesEqual(prevValues, formValues, index))
      ) {
        POLICY_HOLDER_FIELDS.forEach((field) => {
          formValues[`${field}${index}`] = prevValues[mapPolicyHolderFieldToPatientField[field]];
          setValue(`${field}${index}`, prevValues[mapPolicyHolderFieldToPatientField[field]]);
        });
      }

      if (
        formValues[`policy-holder-address-as-patient${index}`] === false &&
        prevValues[`policy-holder-address-as-patient${index}`] === true
      ) {
        POLICY_HOLDER_FIELDS.forEach((field) => {
          formValues[`${field}${index}`] = '';
          setValue(`${field}${index}`, '');
        });
      }
    });
  }, []);

  const onFormValuesChange = useCallback(
    (formValues: FieldValues, setValue: (name: string, value: any) => void): void => {
      updateAddress(formValues, setValue);
      useEligibilityStore.setState({ formValues });
      patchCompletedPaperwork(filterObject(formValues, (key) => !allFileKeys.includes(key)));
    },
    [allFileKeys, patchCompletedPaperwork, updateAddress]
  );

  const formElements = useMemo(() => {
    const mapped = mapQuestionsToFormInputFields(items.filter((item) => item.id !== 'credit-card-id')).reduce(
      (prev, curr) => {
        if (!showSecond && curr.name.endsWith('-2')) {
          return prev;
        } else {
          if (curr.name === 'insurance-carrier-2') {
            curr.required = true;
          }
        }
        prev.push(curr);
        return prev;
      },
      [] as FormInputType[]
    );

    if (!showSecond) {
      mapped.push({
        name: 'show-second-insurance',
        type: 'Button',
        label: 'Add additional insurance',
        buttonProps: {
          onClick: () => setShowSecond(true),
          startIcon: <AddIcon />,
        },
        enableWhen: {
          question: 'payment-option',
          operator: '=',
          answer: 'Insurance',
        },
      });
    } else {
      const index = mapped.findIndex((item) => item.name.endsWith('-2'));

      mapped.splice(index, 0, {
        name: 'show-second-insurance',
        type: 'Button',
        label: 'Remove Secondary Insurance',
        buttonProps: {
          onClick: () => {
            patchCompletedPaperwork({
              'insurance-carrier-2': undefined,
            });
            setShowSecond(false);
          },
          color: 'error',
        },
        enableWhen: {
          question: 'payment-option',
          operator: '=',
          answer: 'Insurance',
        },
      });
    }

    updateFormFieldsRequiredState(insurances, mapped);

    return mapped;
  }, [mapQuestionsToFormInputFields, items, showSecond, insurances, patchCompletedPaperwork]);

  const handleSelectedPaymentMethodChange = (id: string): void => {
    const nextPaymentMethodId = id === ADD_NEW_CARD_OPTION_VALUE ? undefined : id;
    patchCompletedPaperwork({
      [CREDIT_CARD_ITEM_ID]: nextPaymentMethodId,
    });
    setCreditCardError(false);
  };

  const handleValidCardChosen = useCallback((valid: boolean) => setValidCardChosen(valid), []);
  const handleCardsLoading = useCallback((loading: boolean) => setAreCardsLoading(loading), []);

  return (
    <CustomContainer
      title={pageName}
      description={items[0]?.type === 'Description' ? items[0]?.text : undefined}
      bgVariant={currentPage.slug}
    >
      <SelfPayContainer
        setSelected={handleSelectedPaymentMethodChange}
        error={creditCardError}
        selectedPaymentMethodId={completedPaperwork[CREDIT_CARD_ITEM_ID]}
        onCardValidityChange={handleValidCardChosen}
        onLoading={handleCardsLoading}
      />
      <PageForm
        formElements={formElements}
        onSubmit={onSubmit}
        onFormValuesChange={onFormValuesChange}
        controlButtons={useMemo(
          () => ({
            loading: createZ3Object.isLoading || isEligibilityFetching || isEligibilityLoading || areCardsLoading,
            onBack:
              currentIndex === 0 ? () => navigate(IntakeFlowPageRoute.PatientInformation.path) : () => navigate(-1),
          }),
          [
            areCardsLoading,
            createZ3Object.isLoading,
            isEligibilityFetching,
            isEligibilityLoading,
            navigate,
            currentIndex,
          ]
        )}
      />
      <ErrorDialog
        title={errorDialogDetails.title}
        description={errorDialogDetails.message}
        open={errorDialogOpen}
        handleClose={() => setErrorDialogOpen(false)}
        closeButtonText={errorDialogDetails.closeButtonText}
      />
    </CustomContainer>
  );
};

export default PaymentOption;

const stripePromise = loadStripe(import.meta.env.VITE_APP_STRIPE_KEY);

interface SelfPayContainerProps {
  selectedPaymentMethodId: string;
  setSelected: (selectedPaymentMethodId: string) => void;
  onCardValidityChange: (valid: boolean) => void;
  onLoading: (loading: boolean) => void;
  error: boolean;
}

const SelfPayContainer: FC<SelfPayContainerProps> = ({
  selectedPaymentMethodId,
  setSelected,
  onCardValidityChange,
  onLoading,
  error,
}) => {
  const [cards, setCards] = useState<CreditCardInfo[]>([]);
  const [firstValidCard, setFirstValidCard] = useState<CreditCardInfo | undefined>(undefined);
  const [expiredCardIds, setExpiredCardIds] = useState<string[]>([]);
  const defaultCard = useMemo(() => cards.find((card) => card.default), [cards]);
  const otherCards = useMemo(() => cards.filter((card) => !card.default), [cards]);

  const handleCardToSelect = (cards: CreditCardInfo[]): void => {
    const defaultCard = cards.find((card) => card.default);
    const defaultCardExpired = expiredCardIds.find((cardId) => cardId === defaultCard?.id);
    if (defaultCard && !defaultCardExpired) {
      selectPaymentMethod(defaultCard.id);
      return;
    }
    const potentialFirstValidCard: CreditCardInfo | undefined = cards.filter(
      (card) => !expiredCardIds.includes(card.id)
    )[0];
    setFirstValidCard(potentialFirstValidCard);
    selectPaymentMethod(potentialFirstValidCard?.id || ADD_NEW_CARD_OPTION_VALUE);
  };

  const { isFetching: isCardsLoading, refetch: refetchPaymentMethods } = useGetPaymentMethods((data) => {
    const expiredCards = data.cards.filter((card) => {
      const { expMonth, expYear } = card;
      const today = DateTime.now();

      if (today.year > expYear) return true;
      if (today.year === expYear && today.month > expMonth) return true;
      // Expiring soon should be treated as expired
      if (today.year === expYear && today.month === expMonth && today.daysInMonth - today.day < 3) return true;
      return false;
    });
    setExpiredCardIds(expiredCards.map((card) => card.id));

    setCards(data.cards);
    if (!data.cards.map((card) => card.id).includes(selectedOption)) {
      handleCardToSelect(data.cards);
    }
  });
  const { data: setupData, isFetching: isSetupDataLoading } = useSetupPaymentMethod(() => {
    void refetchPaymentMethods();
  });

  const { mutate: deleteMethod, isLoading: isDeleteLoading } = useDeletePaymentMethod();
  const { mutate: setDefault, isLoading: isSetDefaultLoading } = useSetDefaultPaymentMethod();

  const { completedPaperwork } = getSelectors(usePaperworkStore, ['completedPaperwork']);
  const theme = useTheme();
  const [innerForm, setInnerForm] = useState<HTMLElement | null>(null);
  const [selectedOption, setSelectedOption] = useState<string>(
    (expiredCardIds.includes(selectedPaymentMethodId) ? firstValidCard?.id : selectedPaymentMethodId) ||
      ADD_NEW_CARD_OPTION_VALUE
  );

  const [isAddLoading, setIsAddLoading] = useState(false);
  const disabled = isAddLoading || isCardsLoading || isDeleteLoading || isSetDefaultLoading || isSetupDataLoading;

  useEffect(() => {
    onLoading(disabled);
  }, [disabled, onLoading]);

  useEffect(() => {
    setInnerForm(document.getElementById(PAGE_FORM_INNER_FORM_ID));
  }, []);

  useEffect(() => {
    onCardValidityChange(!expiredCardIds.includes(selectedOption));
  }, [expiredCardIds, onCardValidityChange, selectedOption]);

  const onMakePrimary = (id: string): void => {
    setDefault(
      { paymentMethodId: id },
      {
        onSuccess: (): void => {
          setCards((prevState) => prevState.map((card) => ({ ...card, default: card.id === id })));
        },
      }
    );
  };

  const onDelete = (id: string): void => {
    deleteMethod(
      { paymentMethodId: id },
      {
        onSuccess: (): void => {
          const newCards = cards.filter((card) => card.id !== id);
          setCards(newCards);
          setExpiredCardIds(expiredCardIds.filter((expiredId) => expiredId !== id));
          if (newCards.length > 0) {
            handleCardToSelect(newCards);
          } else {
            selectPaymentMethod(ADD_NEW_CARD_OPTION_VALUE);
          }
        },
      }
    );
  };

  const selectPaymentMethod = (id: string): void => {
    setSelected(id);
    setSelectedOption(id);
    if (id !== ADD_NEW_CARD_OPTION_VALUE) {
      onMakePrimary(id);
    }
  };

  if (completedPaperwork['payment-option'] !== 'Self-pay') {
    return null;
  }

  return (
    <>
      {innerForm &&
        createPortal(
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
            }}
          >
            <Card sx={{ p: 2, backgroundColor: otherColors.coachingVisit, borderRadius: 2 }} elevation={0}>
              <Typography color="primary.main">
                By choosing to proceed as self-pay without insurance, the prompt pay rate at the time of service is{' '}
                <b>$100</b>.
              </Typography>
            </Card>

            <Box>
              <BoldPurpleInputLabel>Select the card you want to pay with *</BoldPurpleInputLabel>
              {error && <Typography color="error">Please, choose an appropriate card to continue</Typography>}

              <RadioGroup
                sx={{
                  '.MuiFormControlLabel-label': {
                    width: '100%',
                  },
                  gap: 1,
                }}
                value={selectedOption || ''}
                onChange={(e) => selectPaymentMethod(e.target.value)}
              >
                {isCardsLoading ? (
                  <>
                    <Skeleton variant="rounded" height={48} />
                  </>
                ) : (
                  (defaultCard ? [defaultCard, ...otherCards] : otherCards).map((item) => {
                    const isThisCardExpired = !!expiredCardIds.find((id) => id === item.id);
                    return (
                      <Box key={item.id} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <FormControlLabel
                          value={item.id}
                          disabled={disabled}
                          control={<Radio />}
                          label={
                            <Box
                              sx={{
                                display: 'flex',
                                // flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <Typography>XXXX-XXXX-XXXX-{item.lastFour}</Typography>
                              {isThisCardExpired && <Typography>Expired</Typography>}
                            </Box>
                          }
                          sx={{
                            border: '1px solid',
                            borderRadius: 2,
                            backgroundColor: () => {
                              if (item.id === selectedOption) {
                                return otherColors.lightPurpleAlt;
                              } else {
                                return theme.palette.background.paper;
                              }
                            },
                            borderColor: item.id === selectedOption ? 'primary.main' : otherColors.borderGray,
                            paddingTop: 0,
                            paddingBottom: 0,
                            paddingRight: 2,
                            marginX: 0,
                            minHeight: 46,
                          }}
                        />

                        {item.id === selectedOption && (
                          <LoadingButton
                            loading={isDeleteLoading}
                            disabled={disabled}
                            onClick={() => onDelete(item.id)}
                            variant="outlined"
                            color="error"
                            sx={{ alignSelf: 'start' }}
                          >
                            Delete Card
                          </LoadingButton>
                        )}
                      </Box>
                    );
                  })
                )}

                {!isCardsLoading && (
                  <FormControlLabel
                    value={ADD_NEW_CARD_OPTION_VALUE}
                    disabled={disabled}
                    control={<Radio />}
                    label="Add new card"
                    sx={{
                      border: '1px solid',
                      borderRadius: 2,
                      backgroundColor: () => {
                        if (ADD_NEW_CARD_OPTION_VALUE === selectedOption) {
                          return otherColors.lightPurpleAlt;
                        } else {
                          return theme.palette.background.paper;
                        }
                      },
                      borderColor:
                        ADD_NEW_CARD_OPTION_VALUE === selectedOption ? 'primary.main' : otherColors.borderGray,
                      paddingTop: 0,
                      paddingBottom: 0,
                      paddingRight: 2,
                      marginX: 0,
                      minHeight: 46,
                    }}
                  />
                )}
              </RadioGroup>
            </Box>

            {setupData?.clientSecret && selectedOption === ADD_NEW_CARD_OPTION_VALUE && (
              <Elements stripe={stripePromise} options={{ clientSecret: setupData.clientSecret }}>
                <CreditCardForm
                  clientSecret={setupData.clientSecret}
                  isLoading={isAddLoading}
                  disabled={disabled}
                  setIsLoading={setIsAddLoading}
                  selectPaymentMethod={selectPaymentMethod}
                />
              </Elements>
            )}
          </Box>,
          innerForm
        )}
    </>
  );
};

type CreditCardFormProps = {
  clientSecret: string;
  isLoading: boolean;
  disabled: boolean;
  setIsLoading: (value: boolean) => void;
  selectPaymentMethod: (id: string) => void;
};

const CreditCardForm: FC<CreditCardFormProps> = (props) => {
  const { clientSecret, isLoading, disabled, setIsLoading, selectPaymentMethod } = props;

  const stripe = useStripe();
  const elements = useElements();
  const queryClient = useQueryClient();

  const handleSubmit = async (e: MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();

    if (!stripe || !elements) {
      throw new Error('Stripe ro stripe elements not provided');
    }

    const card = elements.getElement(CardElement);
    if (!card) {
      throw new Error('Stripe card element not found');
    }

    setIsLoading(true);
    const { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, { payment_method: { card } });
    if (!error) {
      const invalidateSetup = queryClient.invalidateQueries({ queryKey: ['setup-payment-method'] });
      const invalidateMethods = queryClient.invalidateQueries({ queryKey: ['payment-methods'] });
      await invalidateSetup;
      await invalidateMethods;

      if (typeof setupIntent.payment_method === 'string') {
        selectPaymentMethod(setupIntent.payment_method);
      }

      card.clear();
    }
    setIsLoading(false);
  };

  return (
    <form>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          alignItems: 'end',
        }}
      >
        <Box sx={{ width: '100%' }}>
          <CardElement
            options={{
              disableLink: true,
              hidePostalCode: true,
            }}
          />
        </Box>

        <LoadingButton loading={isLoading} disabled={disabled} variant="outlined" type="submit" onClick={handleSubmit}>
          Add card
        </LoadingButton>
      </Box>
    </form>
  );
};
